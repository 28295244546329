import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { Route, Redirect } from 'react-router-dom';

import { TitleComponent } from '_components/TitleComponent/TitleComponent';

import {
	logIt,
	empty,
	replaceVars,
	getRandomUrlAppendage
} from '__globals/global-functions';

import {
	addCSSClass,
	removeCSSClass,
} from '__globals/css-functions';

import {
	getJsonContent,
	setContent
} from '__globals/copy-functions';

import {
	processClearErrMsg,
	processClearEntryUpdated
} from '_features/entries/entry-slice';

import { 	
	goNextPage
} from '__globals/page-flow';
	
import {EntryFormRedux} from './EntryForm/EntryFormRedux';
import { processErrMsgDisplay, processClearStatus } from '_features/entries/entry-slice';
import { processJSON } from '_features/promotion/promotion-slice';
import {Trivia} from '_pages/Variations/Trivia/Trivia';
import {CustomContent} from '_pages/Variations/CustomContent/CustomContent';

import * as GC from "../../../__globals/GLOBAL-CONSTANTS";
import PageFlow from "../../__routes/PageFlow";

import TagManager from 'react-gtm-module';

const tagManagerArgs = {
	dataLayer: {
		userId: '001',
		userProject: 'RSP',
		page: 'Enter',
		checkLoginIframe: false
	},
	dataLayerName: 'RSP'
}

var sanitizeHtml = require('sanitize-html');

var addOn = getRandomUrlAppendage("rd");


export const Enter = props => {

	logIt( "Enter IS CALLED", "", false );

	TagManager.dataLayer(tagManagerArgs);

	const dispatch = useDispatch();
	
	const promotion = useSelector(state => state.promotion);
	logIt( "PROMOTION at Enter", promotion, true );
	
	const entry = useSelector(state => state.entry);
	//logIt( "ENTRY AT ENTER", entry, true );

	const gameClaim = useSelector(state => state.entry.gameClaim);
	logIt( "GAME CLAIM AT ENTER", gameClaim, true);

	const variables = useSelector(state => state.entry.variables);
	//logIt( "VARIABLES AT ENTER", variables, true );

	const token = useSelector(state => state.entry.token);
	//logIt( "TOKEN AT ENTER", token, false );

	let error = useSelector(state => state.entry.error);
	//logIt( "ERROR AT ENTER", error, true );

	let entryUpdated = useSelector(state => state.entry.entryUpdated);
	//logIt( "entryUpdated AT IWGameMatch", error, true );

	const setErrorMsgDisplay = (errMsg) => {
		dispatch( processErrMsgDisplay(errMsg) );
	}
	
	
	// LOCAL VARIABLES
	const [isLoading, setLoading] = useState(false);
	const [useRegistration, setUseRegistration] = useState(false);
	const [goNext, setGoNext] = useState(false);

	const setDisplayForm = (display) => {
		if ( display ) {
			removeCSSClass( "register_form", "formHide" );
			addCSSClass( "register_form", "formShow" );
		} else {
			removeCSSClass( "register_form", "formShow" );
			addCSSClass( "register_form", "formHide" );
		}
	}
	
	useEffect(() => {
		
		let entryPageTop = getJsonContent( promotion, "Enter", "entryPageTop", "Fill out the form below to enter", entry );
		setContent( "entry_page_top", entryPageTop );

		let entryFormTop = getJsonContent(promotion, "Enter", "entryFormTop", "By entering this promotion you agree to <i>receive emails</i> from company.", entry);
		setContent("dv_entry_form_top", entryFormTop);

		let triviaOn = promotion.configuration.config?.variations?.trivia?.triviaOn;
		let hideEntry = promotion.configuration.config?.variations?.trivia?.hideEntryFormPreAnswer;
		if ( !empty(triviaOn) && !empty(hideEntry) ) {
			if ( hideEntry ) {
				removeCSSClass( "register_form", "formShow" );
				addCSSClass( "register_form", "formHide" );
			} else {
				removeCSSClass( "register_form", "formHide" );
				addCSSClass( "register_form", "formShow" );
			}
		}
	}, [promotion]);
	
	useEffect(() => {
		if ( !empty(gameClaim) ) {
			if ( entryUpdated ) {
				dispatch( processClearEntryUpdated() );
			} else if ( !empty(entry?.errorMsgDisplay) ) {
				dispatch( processClearErrMsg() )
			} else {
				setGoNext( true );
			}
		}
	}, [gameClaim, entry.errorMsgDisplay] );

	useEffect(() => {
		if ( !empty(variables) ) {
			if ( entryUpdated ) {
				dispatch( processClearEntryUpdated() );
			} else if ( !empty(entry?.errorMsgDisplay) ) {
				dispatch( processClearErrMsg() )
			} else {
				setGoNext( true );
			}
		}
	}, [variables, entry.errorMsgDisplay] );

	useEffect(() => {
		if ( !empty(error) ) {
			setGoNext( true );
		}
	}, [error] );

	if(isLoading) {

		return (<div id="dv_loading" className="background"><div className='loading'><div className='loading-copy'>loading:<div className='scrollingwords'><span>promotion</span><span>interface</span><span>prizes</span><span>rules</span><span>probabilities</span><span>winning plays</span></div></div></div></div>);

	} else if ( goNext ) {

		return( <PageFlow page={GC.PAGE_ENTER} error={error} /> );

	} else {

		return (

			<React.Fragment>

				<TitleComponent title={promotion.configuration.promoCopy.GLOBAL.title} />

				<div id="register_page">

					<table>
						<tbody>
						<tr>
							<td className="page-visual">
								<div className="view-desktop">
									<img
										src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/registration-screen.jpg${addOn}`}/>
								</div>
								<div className="view-phone" style={{display: "none"}}>
									<img
										src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}phone/registration-screen.jpg${addOn}`}/>
								</div>
							</td>
							<td className="page-content">

								<div id="entry_page_top"></div>

								<Trivia setDisplayForm={setDisplayForm} />

								<div id="register_form" className="formShow">

									<div id="dv_entry_form_top"></div>

									<EntryFormRedux
										errorMsgDisplay={entry.errorMsgDisplay}
										setErrorMsgDisplay={setErrorMsgDisplay}
									/>

								</div>

								{promotion?.variables?.promocracyOn && <div id="dv_promocracy-logo">
									<img
										src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/promocracy_logos/${promotion.variables.promocracyLogoImage}${addOn}`}/>
								</div>}

							</td>
						</tr>
						</tbody>
					</table>



					<CustomContent />

				</div>


			</React.Fragment>

		);
	}
    
}

export default Enter;