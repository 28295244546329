export const PAGE_APP = "App";
export const PAGE_ENTER = "Enter";
export const PAGE_ENTER_NOREG = "EnterNoReg";
export const PAGE_THANK_YOU = "ThankYou";
export const PAGE_ERROR = "Error";
export const PAGE_PICK_PRIZE = "PickYourPrize";
export const PAGE_IW_MATCH = "IWGameMatch";
export const PAGE_IW_MATCH_CONFIGURABLE = "IWGameMatchConfigurable";
export const PAGE_IW_WHEEL = "IWGameWheel";
export const PAGE_IW_SLOT = "IWGameSlot";
export const PAGE_IW_NO_CLICK_REVEAL = "IWGameNoClickReveal";
export const PAGE_SPLASH = "SplashPage";
export const PAGE_GNPREDIR = "GNPRedir";
export const PAGE_PRIZES = "Prizes";
export const PAGE_COMING_SOON = "ComingSoon";
export const PAGE_ALREADY_WON = "AlreadyWon";
export const PAGE_ALREADY_ENTERED = "AlreadyEntered";
export const PAGE_ALREADY_PLAYED = "AlreadyPlayed";
export const PAGE_PROMO_ENDED = "PromoEnded";
export const PAGE_THANK_YOU_DEFAULT = "ThankYouDefault";
export const PAGE_LOCAL_MAP = "LocalMap";

export const PROMO_TYPE_SIMPLE_SWEEPS = "simple-sweeps";
export const PROMO_TYPE_IW_MATCH = "iw-match";
export const PROMO_TYPE_IW_MATCH_CONFIGURABLE = "iw-match-configurable";
export const PROMO_TYPE_IW_WHEEL = "iw-wheel";
export const PROMO_TYPE_IW_SLOT = "iw-slot";
export const PROMO_TYPE_IW_NO_CLICK_REVEAL = "iw-no-click-reveal";

export const RESULT_STANDARD = "WinStandard";
export const RESULT_FORM = "WinRedeemForm";
export const RESULT_DIGITAL = "WinSendDigitalPrize";
export const RESULT_INTEGRATION = "WinAppIntegration";
export const RESULT_LOSE = "LoseStandard";
export const RESULT_LOSE_INTEGRATION = "LoseAppIntegration";
export const RESULT_LOSE_OFFER = "LoseOffer";

export const TY_DEFAULT = "ThankYou_Default";
export const TY_REDEEM = "ThankYou_RedeemForm";
export const TY_ECOUPON = "ThankYou_ECoupon";
export const TY_ECARD = "ThankYou_ECard";
export const TY_LOSE_OFFER = "ThankYou_LoseOffer";
export const ALLOWED_TAGS = [ 'img' ];

export const CUSTOM_CONTENT = "Custom_Content";
export const CUSTOM_CAROUSEL = "Custom_Carousel";

export const CUSTOM_CONTENT_TYPE_STANDARD = "standard";
export const CUSTOM_CONTENT_TYPE_CAROUSEL = "carousel";

export const VARIATION_PICK_PRIZE = "pickYourPrize";
export const VARIATION_CUSTOM_CONTENT = "customContent";
export const VARIATION_PROMOCRACY = "promocracy";

export const ACTION_NAVIGATE = "NAVIGATE";

export const ALWAYS_ALLOWED_PAGE_LIST = ["local-map","official-rules","prizes"]

